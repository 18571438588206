.video-container {
  width: 100%;
  margin-bottom: 2rem;
}

body {
  font-family: "Averia Serif Libre", cursive;
  color: white;
  -webkit-text-stroke: 1px black;
  font-size: 28px;
}

.filmbox {
  background-color: rgba(172, 104, 2, 0.658);
  border-radius: 10px;
  border-width: 4px;
  border-color: white;
  border-style: double;
  padding: 5px;
  padding-left: 25px;
  margin-bottom: 10px;
}

a {
  font-family: "Averia Serif Libre", cursive;
  color: white;
  -webkit-text-stroke: 1px black;
  font-size: 28px;
  text-decoration: none;
}

