.nav-padding {
  padding-left: 296px;
  padding-right: 290px;
  padding-bottom: 20px;
}

.navfont {
  font-size: 24px;
  font-weight: 700;
}

.navbar a {
  /* color: rgb(238, 49, 159); */
  color: white;
  -webkit-text-stroke: 1px black;
  font-family: "Averia Serif Libre", cursive;
}

.active {
  text-decoration: underline;
}

.navbox {
  background-color: rgba(172, 104, 2, 0.658);
  border-radius: 10px;
  border-width: 4px;
  border-style: double;
  padding: 3px;
  margin: 2px;
}

.navbox:hover {
  cursor: url("../assets/BladeNormal.png"), auto;
}

/* .igbox {
  background-color: rgba(172, 104, 2, 0.658);
  border-radius: 10px;
  border-width: 4px;
  border-style: double;
  padding: 3px;
  margin: 2px;
} */


.navbar-toggler-icon {
  background-color: rgba(172, 104, 2, 0.658);

}

.nav-link {
  font-size: 18px; /* Adjust font size as needed */
}

@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column; /* Stack the nav links vertically */
  }

  .igbox {
    margin-right: 5%; /* Adjust margin for smaller screens */
    max-width: none; /* Reset maximum width */
    width: 90%; /* Set width to fill 90% of the viewport */
  }

  .nav-link {
    padding: 10px 0; /* Adjust padding to add spacing between links */
    text-align: center; /* Center align the text */
  }

  .nav-padding {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 2px;
  }
}

