/* .box{
  background-color: rgb(214, 117, 6);
} */
@import url("https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:wght@700&display=swap");

.font {
  /* color:rgb(238, 49, 159); */
  color: white;
  -webkit-text-stroke: 1px black;
  font-size: 30px;
  font-weight: 700;
  font-family: "Averia Serif Libre", cursive;
}

.name {
  /* color:rgb(238, 49, 159); */
  color: white;
  -webkit-text-stroke: 1px black;
  font-weight: 700;
}
.pic{
  padding-top: 200px;
}
.box {
  background-color: rgba(172, 104, 2, 0.658);
  border-radius: 10px;
  padding-left: 10px;
  border-style: double;
}


@media (max-width: 992px) {
  .box {
    margin-top: 20px; /* Add some space between the photo and the email on smaller screens */
  }
}

.imgbox {
  background-color: rgba(172, 104, 2, 0.658);
  border-radius: 10px;
  border-style: double;
  padding: 3px;
}

body {
  cursor: url("../assets/BladeNormal.png"), auto;
}

@media only screen and (max-width: 480px) {
  body {
    font-size: 12px;
  }
  
  #photo {
    width: 100%;
  }
}